@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
    background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .cta-button {
    height: 45px;
    border: 0;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: bold;
  }
  
  .connect-wallet-button {
    color: white;
    background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
  }
  
  .mint-button {
    background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    margin-left: 10px;
  }
  
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    /* padding-bottom: 45px; */
  }
  
  .twitter-logo {
    width: 35px;
    height: 35px;
  }
  
  .footer-text {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .connected-container input[type='text'] {
    display: inline-block;
    padding: 10px;
    width: 50%;
    height: 60px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 10px;
    margin: 50px auto;
  }
  
  
  /* KeyFrames */
  @-webkit-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  